import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Switch,Select,Paper,MenuItem,Snackbar } from "@material-ui/core";
import "../css/NotificationSettingsWeb.css";
import Alert from '@material-ui/lab/Alert';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import  DatePicker  from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import "../css/ReactDatePickerCustomWeb.css";
// Customizable Area End
// Code Exception Area Start
import NotificationsettingsController, {
  Props,
} from "./NotificationsettingsController";
// Code Exception Area End
export default class NotificationSettings extends NotificationsettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleReminder=()=>{
    this.setState({
      reminder:!this.state.reminder
    })
  }
  handleVacation=()=>{
    this.setState({
      vacation: !this.state.vacation
    })
  }
  handleEasyMode=()=>{
    this.setState({
      easyMode: !this.state.easyMode,
      reminder:false
      
    })
  }
  reminderClassName =()=> this.state.reminder ? "leftSettingReminderPaper" : "leftSettingsPaper";
  reminderBody = ()=>{
    if(this.state.reminder){
      return (
        <Grid container className="reminderMainGrid">
                      <Grid item md={8} sm={6}>
                        <Typography className="dropDownReminders">First Reminder</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} className="firstReminderGrid">
                      <Select labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.firstReminder === "" ? "None" : this.state.firstReminder }
                          style={{borderRadius: 10, width: "200%", backgroundColor:"rgba(0,0,0,0.10)"}}
                          className="firstReminderDropDown"
                          data-test-id="firstReminderTime"
                          IconComponent={KeyboardArrowDownIcon}
                          onChange={this.handleFirstReminder}
                          MenuProps={{
                            className: 'custom-menu',
                            MenuListProps: {
                              className: 'custom-menu-scrollbar',
                            },
                          }}
                          >
                            {
                              this.state.firstReminderDropDown.map((item:any)=>{
                                return (
                                  <MenuItem key={item} value={item} className="menuItemDropDowns">
                                  {item}
                                  </MenuItem>
                                )
                              })
                            }
                        </Select>
                      </Grid>
                      <Grid item md={8} sm={6}>
                        <Typography className="dropDownReminders">Second Reminder</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} className="secondReminderGrid">
                        <Select labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.secondReminder === "" ? "None" : this.state.secondReminder }
                          style={{borderRadius: 10, width: "200%", backgroundColor:"rgba(0,0,0,0.10)"}} 
                          className="secondReminderDropDown"
                          IconComponent={KeyboardArrowDownIcon}
                          onChange={this.handleSecondReminder}
                          MenuProps={{
                            className: 'custom-menu',
                            MenuListProps: {
                              className: 'custom-menu-scrollbar',
                            },
                          }}
                          >
                        
                        {
                              this.state.secondReminderDropDown.map((item:any)=>{
                                return (
                                  <MenuItem key={item} value={item} className="menuItemDropDowns">
                                  {item}
                                  </MenuItem>
                                )
                              })
                            }</Select>
                      </Grid>
                      <Grid item md={8} sm={6}>
                        <Typography className="dropDownReminders">Morning Reminder</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} className="morningReminderGrid">
                      <Select labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          data-test-id="reminder-select"
                          value={this.state.morningReminder === "" ? "None" : this.state.morningReminder}
                          style={{borderRadius: 10, width: "200%", backgroundColor:"rgba(0,0,0,0.10)"}}
                          className="morningReminderDropDown"
                          IconComponent={KeyboardArrowDownIcon}
                          onChange={this.handleMorningReminder}
                          MenuProps={{
                            className: 'custom-menu',
                            MenuListProps: {
                              className: 'custom-menu-scrollbar',
                            },
                          }}
                          >
                            {
                              this.state.thirdReminderDropDown.map((item:any)=>{
                                return (
                                  <MenuItem key={item} value={item} className="menuItemDropDowns">
                                  {item}
                                  </MenuItem>
                                )
                              })
                            }
                          </Select>
                      </Grid>
                    </Grid>
      )
    }
    else {
      return <></>
    }
  } 
  settingLeftContainer=()=>{
    let containerClassName = "leftSettingsPaper";

    if (this.state.vacation && this.state.reminder) {
      containerClassName = "leftSettingVacationPaper";
    } else if (this.state.reminder || this.state.vacation) {
      containerClassName = "leftSettingReminderPaper";
    }
    else if(this.state.vacation){
      containerClassName = "leftSettingsVacationOffPaper";
    }
    return containerClassName;
  }
  // Customizable Area End
  
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="rootNotificationSettings">
      <Box
        style={{
          width: "100%",
          marginLeft:"-10px"
        }}
      >
        <Grid item sm={12} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "100%" }}>
            <Snackbar open={this.state.isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert severity="error">{this.state.errorMessage}</Alert>
            </Snackbar>
          </Grid>
        <Typography variant="h5" className="settingsHeading">
          Notification Settings
        </Typography>
        
        <Grid container className="settingsContainers">
          <Grid item sm={12} md={6} className="settingsLeft">
          <Paper elevation={3} className={ this.settingLeftContainer() }>
            <Box className="enabledSettingsContainer">
              <Grid container className="reminderGrid">
                <Grid item md={10} sm={6}>
                  <Typography className="switchReminderHeader">Reminder</Typography>
                </Grid>
                <Grid item md={2} sm={6} className="switchForReminderGrid">
                  <Switch color="primary" className={this.reminderClassName()} checked={this.state.reminder} onChange={this.handleReminder}></Switch>
                </Grid>
                { this.reminderBody() }
              </Grid>
              <hr className="hrLine" />

              <Grid container className="vacationGrid">  
                <Grid item md={10} sm={6}>
                  <Typography className="switchHeader">Vacation Mode</Typography>
                </Grid>
                <Grid item md={2} sm={6}>
                  <Switch color="primary" className={ this.state.vacation ? "leftSettingVacationPaper" : "leftSettingsVacationOffPaper" } checked={this.state.vacation} onChange={this.handleVacation}></Switch>
                </Grid>
                { this.state?.vacation &&
                  <Grid container>
                    <Grid item md={10} sm={6}>
                      <Typography className="dropDownReminders">Vacation begin</Typography>
                    </Grid>
                    <Grid item md={2} sm={6} className="vacationGridBegin">
                      <>{console.log("This vacation", this.state.vacationBegin, this.state.vacationEnd,this.state.vacationDefault)}</>
                    <DatePicker className="custom-datepicker" minDate={new Date()} dateFormat = "dd/MM/yyyy" value={this.state.vacationBegin} onChange={this.handleVacationBeginChange}></DatePicker>
                    </Grid>
                    <Grid item md={10} sm={6}>
                      <Typography className="dropDownReminders">Vacation end</Typography>
                    </Grid>
                    <Grid item md={2} sm={6} className="vacationGridEnd">
                          <DatePicker className="custom-datepicker" minDate={this.state.vacationBeginDate} dateFormat = "dd/MM/yyyy" value={this.state.vacationEnd} onChange={this.handleVacationEndChange}></DatePicker>
                    </Grid>
                    <Grid item md={8} sm={6}>
                      <Typography className="defaultDropDownReminders">Default Commitment</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} className="vacationDefaultGrid">
                    <Select labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.vacationDefault}
                        style={{borderRadius: 10, width: "230%", backgroundColor:"rgba(0,0,0,0.10)"}}
                        className="vacationDropDownSelect"
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={this.handleVacationDefault}
                        MenuProps={{
                          className: 'custom-menu',
                          MenuListProps: {
                            className: 'custom-menu-scrollbar',
                          },
                        }}
                        >
                          {
                              this.state.defaultVacationCommitment.map((item:any)=>{
                                return (
                                  <MenuItem key={item.value} value={item.value} className="menuItemDropDowns">
                                  {item.name}
                                  </MenuItem>
                                )
                              })
                            }
                      </Select>
                    </Grid>
                    <Grid>
                      <Typography variant="body2" className="vacationReminderText">
                        Reminders will be halted during your vacation
                      </Typography>
                    </Grid>
                  </Grid>
                  
                 } 
              </Grid>    
              <hr className="hrLineLeft" />
             
            </Box>
          </Paper>
          </Grid>
          <Grid item sm={12} md={6} className="settingsRight">
            <Paper elevation={3} className={ this.state.easyMode ? "rightSettingsEasyPaper" : "rightSettingsPaper" }>
              <Box className="enabledSettingsContainer">
               <Grid container className="easyModeGrid">
                <Grid item md={10}>
                  <Typography className="switchHeader">Easy Mode{this.state.easyModePremium ? "" : "(Premium)"}</Typography>
                </Grid>
                <Grid item md={2}>
                <Switch color="primary" disabled={this.state.easyModePremium} className={ this.state.easyMode ? "rightSettingsEasyPaper" : "rightSettingsPaper" } checked={this.state.easyMode} onChange={this.handleEasyMode}></Switch>
                 </Grid>
                 {this.state?.easyMode && 
                  <Grid container>
                  <Grid item md={10} className="easyModeDropDrownGrids">
                    <Typography className="dropDownReminders">Indicate your preferred default choice for nights ahead</Typography>
                  </Grid>
                  <Grid item md={6} className="easyModeDropDrownGrids">
                  <Select labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.easyModeDefault}
                        style={{borderRadius: 10, width: "100%", backgroundColor:"rgba(0,0,0,0.10)"}}
                        className="easyModeDropDownGrid"
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={this.handleEasyModeDefault}
                        MenuProps={{
                          className: 'custom-menu',
                          MenuListProps: {
                            className: 'custom-menu-scrollbar',
                          },
                        }}
                      >
                     { this.state.easyModeDefaultList.map((item:any)=>{
                            return (
                              <MenuItem key={item.value} value={item.value} className="menuItemDropDowns">
                                {item.name}
                              </MenuItem>
                            )
                        })
                      }
                     </Select>
                  </Grid>
                  <Grid item md={10} className="easyModeDropDrownGrids">
                    <Typography className="dropDownReminders">Select time for easy-mode prompt</Typography>
                  </Grid>
                  <Grid item md={6} className="easyModeDropDrownGrids">
                    <Select labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.easyModeFirst}
                      style={{borderRadius: 10, width: "100%", backgroundColor:"rgba(0,0,0,0.10)"}}
                      className="easyModeDropDownGrid"
                      IconComponent={KeyboardArrowDownIcon}
                      onChange={this.handleEasyModeFirst}
                      MenuProps={{
                        className: 'custom-menu',
                        MenuListProps: {
                          className: 'custom-menu-scrollbar',
                        },
                      }}
                      >
                       {
                        this.state.easyModeFirstList.map((item:any)=>{
                            return (
                              <MenuItem key={item} value={item} className="menuItemDropDowns">
                                {item}
                              </MenuItem>
                            )
                        })
                      }
                     </Select>
                  </Grid>
                  <Grid item md={10} className="easyModeDropDrownGrids">
                    <Typography className="dropDownReminders">Select time for an optional second easy-mode prompt</Typography>
                  </Grid>
                  <Grid item md={6} className="easyModeDropDrownGrids">
                  <Select labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.easyModeSecond}
                      style={{borderRadius: 10, width: "100%", backgroundColor:"rgba(0,0,0,0.10)"}}
                      className="easyModeDropDownGrid"
                      IconComponent={KeyboardArrowDownIcon}
                      onChange={this.handleEasyModeSecond}
                      MenuProps={{
                        className: 'custom-menu',
                        MenuListProps: {
                          className: 'custom-menu-scrollbar',
                        },
                      }}
                      >
                      {
                        this.state.easyModeSecondList.map((item:any)=>{
                            return (
                              <MenuItem key={item} value={item} className="menuItemDropDowns">
                                {item}
                              </MenuItem>
                            )
                        })
                      }
                      </Select>
                  </Grid>
                </Grid>
                
                 }
              </Grid> 
              <hr className="hrLineRight" />
              
              </Box>
            </Paper>
            </Grid>
        </Grid>
      </Box>
    </Container>
    
      // Customizable Area End
    );
  }
}

// Customizable Area End
